<template>
  <div>
    <!-- {{tableData[0]}} -->
    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
            data.index +
            1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark">
          <feather-icon icon="ImageIcon" /> </b-avatar>{{ "  " }}
        {{ data.item.ref }}
      </template>

      <template #cell(forms)="data">
        {{ (data && data.item.forms && data.item.forms.length) || 0 }}
      </template>
      <template #cell(actions)="data">
        <b-button
          v-if="true"
          variant="primary"
          :to="{
            name: 'agents-details',
            params: { agentId: data.item._id },
          }"
        >
          <feather-icon icon="EyeIcon" />
          <span class="text-nowrap"> Détails</span>
        </b-button>
      </template>
    </b-table>

    <div>
      <PaginationComponent
        :table-data="tableData"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
      />
    </div>
    <!-- <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <span
          class="my-auto"
        >Affichage de {{ paginationData.metaData.from }} à
          {{ paginationData.metaData.to }} sur {{ paginationData.total }}
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCardText,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'

// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import rolesStoreModule from '@/store/roles'
import usersStoreModule from '@/store/users'
import agentGouvenementStoreModule from '@/store/agent-gouvenement'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'roles', module: rolesStoreModule },
      { path: 'agentGouvenement', module: agentGouvenementStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  name: 'ListAgentsComponent',
  components: {
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCardText,
    PaginationComponent,
  },

  props: { etablissementId: null },
  data() {
    return {
      form: {
        role: '',
      },

      confirmeArchiveAgentModalRef: 'confirmeArchiveAgentModal',

      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        {
          key: 'id', stickyColumn: true, label: '#', sortable: true,
        },
        {
          key: 'firstname', stickyColumn: true, label: 'Nom', sortable: true,
        },
        {
          key: 'lastname', stickyColumn: true, label: 'Prénom', sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          stickyColumn: true,
        },
        // {
        //   key: 'etablissement',
        //   label: 'Etablissement',
        //   sortable: true,
        // },
        // {
        //   key: 'forms',
        //   label: 'Nombre de fiche remplies',
        //   sortable: true,
        // },
        'actions',
      ],
      isLoadingTableData: false,
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  computed: {
    ...mapGetters('params', {
      typesRoles: 'getter_typesRoles',
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        console.log('route change')
        this.getListUsers()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData)
        }
      },
    },
    selectedCategorie: {
      immediate: true,
      async handler(val, old) {
        await this.action_fetchTypesRoles()
        this.getListUsers()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
  },
  methods: {
    ...mapActions('users', { action_fetchUsers: 'fetchUsers' }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('etablissements', { getAgent: 'getAgent' }),
    ...mapActions('agentGouvenement', {
      createAgentGouvenement: 'createAgentGouvenement',
      findAgentGouvenement: 'findAgentGouvenement',
    }),
    ...mapActions('roles', {
      fetchRoles: 'fetchRoles',
    }),

    async getListUsers() {
      this.isLoadingTableData = true
      const userRoleReq = await this.action_fetchTypesRoles().catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message || 'Une erreur est survenue',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      if (!userRoleReq || !userRoleReq.data) {
        this.isCheckingAgentCount = false
        this.canAddNewAgent = false
        return
      }

      const { resources } = userRoleReq.data

      const userRole = (resources || []).find(e => e.code === 'agent_mtca')
      console.log('this.$props: ', this.$props)
      const userRoleId = userRole._id
      const params = {
        options: {
          role: userRoleId,
          isArchive: false,
          ...(this.etablissementId && { etablissement: this.etablissementId }),
        },
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      }
      this.action_fetchUsers(params)
        .then(async response => {
          this.tableData = response.data.resources || []
          console.log('this.tableData: ', this.tableData)
          // // get forms by agents
          // for (let i = 0; i < this.tableData.length; i++) {
          //   // console.log('this.tableData: ', this.tableData[i]._id)
          //   await this.getAgent(this.tableData[i]._id)
          //     .then(res => {
          //       // console.log('res.data.formsCreate: ', res.data.formsCreate)
          //       let forms = []
          //       res.data.formsCreate.map(item => {
          //         forms = forms.concat(item.data)
          //         this.tableData[i].forms = forms
          //         console.log('forms: ', forms)
          //         console.log('this.tableData[i].forms: ', this.tableData[i].forms)
          //       })
          //     })
          //     .catch(err => { console.log(err) })
          // }

          this.paginationData.total = response.data.total || 0
          this.isLoadingTableData = false

          if (this.paginationData.metaData.from == 0) { this.paginationData.metaData.from += 1 } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + this.tableData.length
        })
        .catch(err => {
          this.isLoadingTableData = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          console.log('error-users-list', err)
        })

      // setTimeout(() => {
      //   this.tableData = new Array(200)
      //     .fill({
      //       nom: 'Nom',
      //       prenom: 'Prenom',
      //       email: 'user@user.com',
      //     })
      //     .map((e, i) => {
      //       const temp = JSON.parse(JSON.stringify(e))
      //       return { ...temp, id: i }
      //     })

      //   this.isLoadingTableData = false
      // }, 2000)
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
